import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import { Helmet } from "react-helmet";


export default function Contact() {
  const Site_Url = "https://newspaperadvertisement.co.in/";
  const BASE_URL = Site_Url + "admin/API/";

  const [bannerdata, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.ContactMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ContactMetaKeyword} />
        <meta name="description" content={PagemetaTags.ContactMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Banner
        Bnanerimage={bannerdata.contact_banner}
        image_title={bannerdata.contact_banner_title}
        image_alt={bannerdata.contact_banner_alt}
      />

      <section className="contact_section section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title mb-5">
                <h2 className="title text-dark">Get in Touch!</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="contact_box">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </li>
                  <li>
                    <h5>Call</h5>
                  </li>
                  <li>
                    <span>
                      <a href={`tel:${webSetting.number}`}>
                        {webSetting.number}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>9:30 AM to 6:00 PM</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="contact_box">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                  </li>
                  <li>
                    <h5>Complain</h5>
                  </li>
                  <li>
                    <span>
                      <a href={`tel:${webSetting.number}`}>
                        {webSetting.number}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>10:00 AM to 6:00 PM</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="contact_box">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  </li>
                  <li>
                    <h5>Email us</h5>
                  </li>
                  <li>
                    <span>
                      <a href={`mailto:${webSetting.email}`}>
                        {webSetting.email}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span>24 * 7</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact Section Start */}
      <section className="HomeContact_section section_padding service_bg section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-4">
              <div className="section_title mb-5">
                <h2 className="title text-light">Send Us a Message</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mt-3">
              <div className="homeContact_img">
              <iframe
                  width="100%"
                  height="450"
                  src={`https://www.youtube.com/embed/${webSetting.iframe_video}`}
                  title="Father Name Change Consultant in India | Fatherame Change Agent in India"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-3">
              <div className="form_id" id="form_id">
                <iframe
                  width="100%"
                  height="450"
                  src="https://manage.yourdoorstep.co/forms/ticket"
                  frameBorder="0"
                  allowFullScreen
                  title="form"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact Section End */}

      <section className="contact_address section_padding section_padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title mb-5">
                <h2 className="title text-dark">Our Address</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              {/* <div className="brach_bg_one AddBox_bg2">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </li>
                  <li>
                    <h5>Ghaziabad</h5>
                  </li>
                  <li>
                    <span>
                      Chamber No-A/6 Tehsil Compund Gandhi Nagar,Ghaziabad
                    </span>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-md-4 mb-2">
              <div className="brach_bg_one AddBox_bg1">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </li>
               
                  <li>
                    <span>{webSetting.address}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 mb-2">
              {/* <div className="brach_bg_one AddBox_bg3">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                  </li>
                  <li>
                    <h5>Noida</h5>
                  </li>
                  <li>
                    <span>Chamber No-42 Sector-33 Noida</span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
